import { Formik, Form, Field, useField } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import app from "../../firebase";
import {
  getFirestore,
  doc,
  setDoc,
  updateDoc,
  runTransaction,
} from "firebase/firestore";
import { getCookie, setCookie } from "../../functions/cookie";
import * as Yup from "yup";
import uploadIcon from "../../assets/images/upload-icon.png";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";

const FileUpload = ({ fileRef, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <div>
      <label htmlFor="files">Choose files</label>{" "}
      <input
        ref={fileRef}
        type="file"
        {...field}
        accept="image/jpeg, image/jpg, image/png"
        capture="environment"
        // onChange={(e) => {
        //   let fileName = e.target.files[0].name;
        //   console.log(e.target.files[0]);
        //   props.setfile(fileName.substring(fileName.lastIndexOf("\\") + 1));
        //   console.log(e.target.files[0]);
        // }}
        required
      />
      {/* {meta.touched && meta.error ? (
        <div style={{ color: "red" }}>{meta.error}</div>
      ) : null} */}
    </div>
  );
};

function ClaimForm(props) {
  const db = getFirestore(app);
  const location = getCookie("location");
  const prize = getCookie("prize");
  const outlet = getCookie("outlet");
  const prizeType = getCookie("prize_type");
  const fileRef = useRef(null);
  const token = getCookie("token");

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState(0);
  const [address, setAddress] = useState("");
  const [bill, setBill] = useState();
  const [billURL, setBillURL] = useState("");
  const [disableSubmitBtn, setDisableSubmitBtn] = useState(false);
  // const increment = FieldValue.increment(-1);

  useEffect(() => {
    setBill();
  }, []);

  const confirmSubmission = () => {
    setCookie("screen", "confirmation", 1);
    props.updatePopup({ display: true, type: "confirmation" });
  };

  const handleInputChange = (e) => {
    let inputName = e.target.name;
    switch (inputName) {
      case "name":
        setName(e.target.value);
        break;
      case "email":
        setEmail(e.target.value);
        break;
      case "phone":
        setPhone(e.target.value);
        break;
      case "address":
        setAddress(e.target.value);
        break;
      case "bill":
        setBill(e.target.files[0]);
        break;
      default:
        return;
    }
  };

  const storeBill = async () => {
    if (bill === undefined) {
      return;
    }
    let uploadTask = "";
    const storage = getStorage();
    const date = new Date();
    const fileName = date.getTime();
    const storageRef = ref(storage, "bills/" + fileName);
    uploadTask = await uploadBytesResumable(storageRef, bill);
    return uploadTask;
  };

  const submitData = async (downloadURL) => {
    let URL = "";
    if (downloadURL !== undefined) {
      URL = downloadURL;
    }
    let deliveryStatus = "undelivered";
    let deliveryAddress = "undelivered";
    const date = new Date();
    let dateInMilliSeconds = date.getTime();
    let postData = {
      name: name,
      phone: phone,
      date: date,
      delivery_status: deliveryStatus,
      location: location,
      outlet_name: outlet,
      prize: prize,
      bill: URL,
      prize_type: prizeType,
      address: address,
    };

    if (prizeType === "cap") {
      const capRef = doc(db, "prize_items", "caps");
      try {
        await runTransaction(db, async (transaction) => {
          const capDoc = await transaction.get(capRef);
          if (!capDoc) {
            console.log("Document does not exists.");
          }

          const newCount = capDoc.data().count - 1;
          transaction.update(capRef, { count: newCount });
        });
        console.log("Transaction successfully completed");
      } catch (e) {
        console.log("Transaction failed", e);
      }
    } else {
      const tshirtRef = doc(db, "prize_items", "tshirts");
      try {
        await runTransaction(db, async (transaction) => {
          const tshirtDoc = await transaction.get(tshirtRef);
          if (!tshirtDoc) {
            console.log("Document does not exists.");
          }

          const newCount = tshirtDoc.data().count - 1;
          transaction.update(tshirtRef, { count: newCount });
        });
        console.log("Transaction successfully completed");
      } catch (e) {
        console.log("Transaction failed", e);
      }
    }

    await setDoc(doc(db, "customers", dateInMilliSeconds.toString()), postData);
    confirmSubmission();
  };

  const submitForm = async (e) => {
    e.preventDefault();
    setDisableSubmitBtn(true);

    if (bill === undefined) {
      alert("Please upload the bill");
      setDisableSubmitBtn(false);
      return;
    }

    if (name === "" || phone === 0 || address === "") {
      alert("Please enter all the details");
      setDisableSubmitBtn(false);
      return;
    }

    let downloadURL;
    storeBill()
      .then(async (res) => {
        downloadURL = await getDownloadURL(res.ref).then((downloadURL) => {
          return downloadURL;
        });
        submitData(downloadURL);
      })
      .catch((err) => {
        alert("An error occured submitting the form. Please try again");
        setDisableSubmitBtn(false);
        return;
      });
  };

  return (
    <Container className="claim-form">
      <Row>
        <Col>
          <h2 className="claimform-heading">
            {prizeType !== "drink"
              ? "Unlock the offer by entering your information"
              : ""}
          </h2>

          <form>
            {prizeType === "drink" ? (
              <div className="token">
                <h2>{token}</h2>
                <p>Share the code with the server to claim the offer</p>
              </div>
            ) : (
              <>
                <div className="file-upload-wrapper">
                  <div className="file-upload">
                    <div>
                      {bill === undefined ? (
                        <>
                          <img src={uploadIcon} alt="" />
                          <p>Upload the Bill</p>
                        </>
                      ) : (
                        <p>{bill.name}</p>
                      )}
                    </div>
                  </div>
                  <input
                    type="file"
                    name="bill"
                    className="input-file"
                    onChange={(e) => handleInputChange(e)}
                    required
                  />
                </div>
                <input
                  type="text"
                  name="name"
                  placeholder="Name"
                  className="textbox"
                  value={name}
                  onChange={(e) => handleInputChange(e)}
                  required
                />
                <input
                  type="tel"
                  name="phone"
                  placeholder="Phone"
                  className="textbox"
                  // value={phone}
                  onChange={(e) => handleInputChange(e)}
                  required
                />
                <textarea
                  type="text"
                  name="address"
                  placeholder="Address"
                  className="textbox"
                  value={address}
                  onChange={(e) => handleInputChange(e)}
                  required
                />
              </>
            )}

            {prizeType !== "drink" ? (
              <div className="button-container">
                <button
                  type="submit"
                  disabled={disableSubmitBtn}
                  onClick={(e) => submitForm(e)}
                >
                  Claim the Offer
                </button>
              </div>
            ) : (
              ""
            )}
          </form>
        </Col>
      </Row>
    </Container>
  );
}

export default ClaimForm;
