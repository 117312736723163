import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { setCookie } from "../functions/cookie";
import data from "../assets/outlets.json";
import { Link } from "react-router-dom";

function Location(props) {
  // states
  const [location, setLocation] = useState("");
  const [outlets, setOutlets] = useState([]);
  const [outlet, setOutlet] = useState("");

  // variables
  const outletInfo = data;

  // side effects

  useEffect(() => {
    outletInfo.sort(function (a, b) {
      if (a.location < b.location) {
        return -1;
      }
      if (a.location > b.location) {
        return 1;
      }
      return 0;
    });

    setLocation(outletInfo[0].location);
  }, []);

  // load outlet details based on the location on change of the lcoation
  useEffect(() => {
    outletInfo.map((outletDetails) => {
      if (outletDetails.location === location) {
        let sortedOutlets = outletDetails.outlets.sort();
        setOutlets(sortedOutlets);
        setOutlet(sortedOutlets[0]);
      }
    });
  }, [location]);

  const handleLocation = (e) => {
    setLocation(e.target.value);
  };

  const handlePopup = () => {
    props.updatePopup((prevState) => {
      return { ...prevState, display: true };
    });
    setCookie("location", location, 1);
    setCookie("outlet", outlet, 1);
    setCookie("screen", "spinWheel", 1);
    setCookie("attempt", 1, 1);
  };

  const handleOutlet = (e) => {
    setOutlet(e.target.value);
  };

  // checkbox validation
  const [isChecked, setIsChecked] = useState(false);
  const handleOnChange = () => {
    setIsChecked(!isChecked);
  };

  return (
    <>
      <Container fluid className="location">
        <Row>
          <Col>
            <h2>Unlock incredible offers with just a few quick details!</h2>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form>
              <Form.Select
                aria-label="Select Location"
                value={location}
                onChange={(e) => handleLocation(e)}
              >
                {outletInfo.map((outlet) => {
                  return (
                    <option value={outlet.location}>{outlet.location}</option>
                  );
                })}
              </Form.Select>

              <Form.Select
                aria-label="Select Outlet"
                onChange={(e) => handleOutlet(e)}
              >
                {outlets.map((outletName) => {
                  if (outlet === outletName) {
                    return (
                      <option value={outletName} selected>
                        {outletName}
                      </option>
                    );
                  } else {
                    return <option value={outletName}>{outletName}</option>;
                  }
                })}
              </Form.Select>

              <Form.Check
                style={{ color: "#ffffff" }}
                onChange={handleOnChange}
                type={"checkbox"}
                id={`checkbox`}
                label={`To enter, you must be over legal purchase age`}
                required
              ></Form.Check>

              <div className="button-container">
                <Button
                  type="button"
                  disabled={!isChecked}
                  onClick={() => handlePopup()}
                >
                  View Offers
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
        <Row>
          <Col>
            <p className="disclaimer">
              By entering this site you agree to our{" "}
              <Link to="/terms" target="_blank">
                Terms & Conditions
              </Link>{" "}
              and{" "}
              <Link to="/terms" target="_blank">
                Privacy & Cookies
              </Link>{" "}
              Notice
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Location;
