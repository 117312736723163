import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import Helmet from "react-helmet";
import Winwheel from "winwheel/lib/Winwheel";
import wheelImage from "../../assets/images/wheel.png";
import wheelImageMobile from "../../assets/images/wheel-mobile-23.png";
import wheelCenter from "../../assets/images/wheel-center.png";
import prizePointer from "../../assets/images/prize-pointer-4.png";
import { deleteCookie, getCookie, setCookie } from "../../functions/cookie";
import Logo from "../Logo";
import { collection, getDocs, getFirestore } from "firebase/firestore";
import app from "../../firebase";

const ResultContent = (props) => {
  const [content, setContent] = useState("");
  const result = props.result;
  switch (result) {
    case "goodie":
      setContent("Grant's Merchandise");
      break;
    case "two_plus_one":
      setContent("Crewtime Special 2+1");
      break;
    default:
      setContent("Try again");
      break;
  }
  return (
    <div className="spin-wheel-result">
      {result !== "try_again" ? <p>You've Won</p> : ""}
      <h2>{content}</h2>
    </div>
  );
};

const CreateWheel = (props) => {
  const [showClaimButton, setShowClaimButton] = useState(false);
  const [showResetButton, setShowResetButton] = useState(false);
  const [result, setResult] = useState("");
  const [displayResult, setDisplayResult] = useState(false);
  const [heading, setHeading] = useState(
    "Looking for reasons to celebrate with your crew?"
  );
  const [subHeading, setSubHeading] = useState("");
  const [resultAngle, setResultAngle] = useState(null);
  const attempt = getCookie("attempt");
  const prize_type = getCookie("prizeType");
  const caps = props.caps;
  const tshirts = props.tshirts;
  let theWheel = useRef();

  let mainContainer = document.getElementById("main_container");

  const resetWheel = () => {
    setCookie("attempt", attempt - 1, 1);
    deleteCookie("prize");
    mainContainer.classList.remove("fireworks");
    setDisableAnimateBtn(false);
    setHeading("Looking for reasons to celebrate with your crew?");
    setSubHeading("");
    setDisplayResult(false);
    // theWheel.current.stopAnimation(false); // Stop the animation, false as param so does not call callback function.
    theWheel.current.rotationAngle = 0; // Re-set the wheel angle to 0 degrees.
    theWheel.current.draw(); // Call draw to render changes to the wheel.
    theWheel.current.wheelSpinning = false;
    deleteCookie("prize_type");
    deleteCookie("prize");
    setShowResetButton(false);
    setShowClaimButton(false);
    getWheelAngle();
  };

  function resizeCanvas() {
    // Get the canvas
    var canvas = document.getElementById("offerWheel");

    // Get width of window.
    let w, h;
    if (window.innerWidth < 600) {
      w = window.innerWidth - 72;
      h = window.innerWidth - 72;
    } else {
      w = 366;
      h = 366;
    }

    // Set height to the current height of the canvas since we don't adjust it.
    canvas.height = h;
    // Assuming only the width will change to be responsive.
    canvas.width = w;

    // To ensure the wheel stays inside the canvas, work out what is the smaller
    // value between width and height, and set the outerRadius to half of that.
    if (w < h) {
      theWheel.current.outerRadius = w / 2;
    } else {
      theWheel.current.outerRadius = h / 2;
    }

    // In order to keep the wheel in the center of the canvas the centerX and
    // centerY need to be set to the middle point of the canvas.
    theWheel.current.centerX = canvas.width / 2;
    theWheel.current.centerY = canvas.height / 2;

    // Other possible TODO
    // - Alter the font size.
    // - Adjust inner radius if the wheel has one.

    // Re-draw the wheel.
    theWheel.current.draw();
  }

  useEffect(() => {
    theWheel.current = new Winwheel({
      canvasId: "offerWheel",
      drawMode: "image",
      drawText: true,
      centerX: 160,
      centerY: 160,
      // textOrientation: "curved",
      textAlignment: "outer",
      textMargin: 5,
      textFontFamily: "courier",
      numSegments: 6,
      responsive: true,
      segments: [
        // drink
        { textFillStyle: "rgba(0,0,0,0)", text: "one_plus_one" },
        // reset
        { textFillStyle: "rgba(0,0,0,0)", text: "try_again" },
        // tshirt
        { textFillStyle: "rgba(0,0,0,0)", text: "branded_tshirt" },
        //reset
        { textFillStyle: "rgba(0,0,0,0)", text: "one_plus_one" },
        // cap
        { textFillStyle: "rgba(0,0,0,0)", text: "branded_cap" },
        // reset
        { textFillStyle: "rgba(0,0,0,0)", text: "try_again" },
      ],
      imageOverlay: true,
      lineWidth: 1,
      strokeStyle: "rgba(0,0,0,0)",
      rotationAngle: 0,
      animation: {
        type: "spinToStop",
        duration: 5,
        spins: 12,
      },
    });

    // Create new image object in memory.
    let loadedImg = new Image();
    loadedImg.width = 272;
    loadedImg.height = 272;

    // Create callback to execute once the image has finished loading.
    loadedImg.onload = function () {
      theWheel.current.wheelImage = loadedImg; // Make wheelImage equal the loaded image object.

      let canvas = document.getElementById("offerWheel");
      let ctx = canvas.getContext("2d");
      ctx.imageSmoothingEnabled = true;
      theWheel.current.draw(); // Also call draw function to render the wheel.
    };
    loadedImg.src = wheelImageMobile;
  }, []);

  const wheelResult = () => {
    setTimeout(async () => {
      let winningSegment = theWheel.current.getIndicatedSegment();
      if (winningSegment.text === "try_again") {
        setShowClaimButton(false);
        setShowResetButton(true);
        setHeading("");
        setSubHeading("");
      } else {
        setHeading("Cheers");
        setSubHeading("");
        mainContainer.classList.add("fireworks");
        let audioElm = document.getElementById("audio2");
        // await audioElm.play();
        if (winningSegment.text === "branded_tshirt") {
          setCookie("prize_type", "tshirt", 1);
        } else if (winningSegment.text === "branded_cap") {
          setCookie("prize_type", "cap", 1);
        } else {
          setCookie("prize_type", "drink", 1);
        }
      }

      theWheel.current.rotationAngle = 0; // Re-set the wheel angle to 0 degrees.
      theWheel.current.draw(); // Call draw to render changes to the wheel.
      theWheel.current.wheelSpinning = false;
      setShowResetButton(false);
      setShowClaimButton(false);
      setCookie("prize", winningSegment.text, 1);
      mainContainer.classList.remove("fireworks");
      props.updatePopup({ display: true, type: "claimPrize" });
      setCookie("screen", "claimPrize", 1);
    }, 1000);
  };

  const [disableAnimateBtn, setDisableAnimateBtn] = useState(false);

  const prizes = [
    {
      prize_type: "one_plus_one",
      probability: 16,
      angle: 1,
    },
    {
      prize_type: "tshirt",
      probability: 7,
      angle: 121,
    },
    {
      prize_type: "cap",
      probability: 7,
      angle: 241,
    },
    {
      prize_type: "try_again",
      probability: 70,
      angle: 300,
    },
  ];

  const getWheelAngle = () => {
    // The angle one the wheel one area uses (uniform sizes)

    var result = prizes[0];

    // Generate a list of the indices based on the probabilities
    var weightedList = [];
    for (let i = 0; i < prizes.length; i++) {
      for (let j = 0; j < prizes[i].probability * 100; j++) {
        weightedList.push(i);
      }
    }

    // Get a random index from the weightedList and use it to get the price
    var winningPriceIndex =
      weightedList[Math.floor(Math.random() * weightedList.length)];
    result = prizes[winningPriceIndex];
    if (result.prize_type === "cap") {
      if (caps <= 0) {
        setResultAngle(prizes[3]);
      } else {
        setResultAngle(prizes[2]);
      }
    } else if (result.prize_type === "tshirt") {
      if (tshirts <= 0) {
        setResultAngle(prizes[3]);
      } else {
        setResultAngle(prizes[1]);
      }
    } else {
      setResultAngle(result);
    }
  };

  useEffect(() => {
    getWheelAngle();
  }, []);

  useEffect(() => {
    console.log(resultAngle);
  }, [resultAngle]);

  const animateWheel = async () => {
    setDisableAnimateBtn(true);
    let audioElm = document.getElementById("audio");
    // await audioElm.play();
    setShowResetButton(false);
    // let prize = await getWheelAngle();
    // console.log(prize);
    let stopAt = resultAngle?.angle + Math.floor(Math.random() * 43);
    theWheel.current.animation.stopAngle = stopAt;
    // if (theWheel.current.wheelSpinning === false) {
    theWheel.current.startAnimation();
    theWheel.current.wheelSpinning = true;
    // }
    setTimeout(() => {
      wheelResult();
    }, 5000);
  };

  const claimPrize = () => {
    mainContainer.classList.remove("fireworks");
    props.updatePopup({ display: true, type: "claimForm" });
    setCookie("screen", "claimForm", 1);
  };

  return (
    <>
      <div className="spin-wheel-heading">
        <h2>{heading}</h2>
        <p>{subHeading}</p>
      </div>
      <div className="wheel-container">
        <audio
          id="audio"
          controls
          src="spin-wheel.mp3"
          type="audio/mp3"
        ></audio>
        <audio id="audio2" controls src="ta-da.mp3" type="audio/mp3"></audio>
        <canvas id="offerWheel" width="320" height="378">
          Canvas not supported, use another browser.
        </canvas>
        {!displayResult ? (
          <>
            <img
              src={prizePointer}
              className="prize-pointer"
              alt=""
              width="17"
            />
            <img src={wheelCenter} className="wheel-center" alt="" />
          </>
        ) : (
          <div>
            {result === "one_plus_one" ? (
              <div className="spin-wheel-result">
                <p>You've Won</p>
                <h2>Crewtime Special 1+1</h2>
              </div>
            ) : result === "branded_tshirt" ? (
              <div className="spin-wheel-result">
                <p>You've Won</p>
                <h2>Grant’s Branded T-Shirt</h2>
              </div>
            ) : result === "branded_cap" ? (
              <div className="spin-wheel-result">
                <p>You've Won</p>
                <h2>Grant’s Branded Cap</h2>
              </div>
            ) : (
              <div className="spin-wheel-result">
                {attempt > 0 ? (
                  <h2>Try Again</h2>
                ) : (
                  <h2>Better luck next time</h2>
                )}
              </div>
            )}
          </div>
        )}
      </div>

      <div className="button-container">
        {showClaimButton && !showResetButton ? (
          <Button onClick={() => claimPrize()} className="claim">
            Claim Prize
          </Button>
        ) : !showResetButton ? (
          <Button disabled={disableAnimateBtn} onClick={() => animateWheel()}>
            Spin the Wheel
          </Button>
        ) : (
          ""
        )}

        {showResetButton && attempt > 0 ? (
          <Button onClick={() => resetWheel()}>Reset Wheel</Button>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

function SpinWheel(props) {
  const [itemData, setItemData] = useState([]);
  const [caps, setCaps] = useState(null);
  const [tshirts, setTshirts] = useState(null);
  const getItemData = async () => {
    const db = getFirestore(app);
    const querySnapshot = await getDocs(collection(db, "prize_items"));
    querySnapshot.forEach((doc) => {
      let id = doc.id;
      let data = doc.data();
      setItemData((prevState) => [...prevState, { id: id, data: data }]);
    });
  };

  useEffect(() => {
    getItemData();
  }, []);

  const popultateItemCount = (itemData) => {
    itemData.map(async (data) => {
      if (data.id === "caps") {
        await setCaps(data.data.count);
      } else if (data.id === "tshirts") {
        await setTshirts(data.data.count);
      } else {
        return;
      }
    });
  };

  useEffect(() => {
    if (itemData.length > 0) {
      popultateItemCount(itemData);
    }
  }, [itemData]);

  return (
    <>
      <Helmet>
        <script
          src="https://cdnjs.cloudflare.com/ajax/libs/gsap/latest/TweenMax.min.js"
          type="text/javascript"
        />
      </Helmet>
      <Logo />
      <Container fluid className="spin-wheel-heading">
        <Row>
          <Col>
            {caps !== null && tshirts !== null ? (
              <CreateWheel
                updatePopup={props.updatePopup}
                caps={caps}
                tshirts={tshirts}
              />
            ) : (
              ""
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default SpinWheel;
