import React from "react";

function Terms() {
  return (
    <div className="terms">
      <h2>TERMS AND CONDITIONS</h2>
      <ol>
        <li>The contest shall be known as "Crewtime Specials".</li>
      </ol>

      <h3>Contest Duration:</h3>
      <ul>
        <li>
          The contest starts from 1<sup>st</sup> May 2024 and shall be
          terminated at the will of WG&SI.
        </li>
      </ul>

      <h3>Eligibility:</h3>
      <ul>
        <li>
          The Competition is open to all India residents aged 25 or over on the
          Competition start date. Eligibility requirements include a valid name,
          residential address and contact number.
        </li>
        <li>Participation in this contest is purely voluntary.</li>
        <li>
          The participation is basis the purchase of Grants Triple Wood Scotch
          Whisky in the quantity as specified in the offer at the outlet.
        </li>
        <li>
          WG&SI shall not charge any amount for participation in the contest and
          the sole criteria for participation shall be submitting entries in the
          manner stipulated below.
        </li>
        <li>
          Employees of William Grant and Sons India Private Limited (WG&SI) &
          organizations related to the contest and their families are excluded
          from entering the Competition.
        </li>
      </ul>

      <h3>General Terms and Conditions:</h3>
      <ul>
        <li>
          This contest will be governed by these standard terms and conditions.
          Each participant agrees that he/she has read and understood these
          terms and by their participation in the contest, each participant
          agrees to be bound by the terms. Mere participation should not be
          perceived as any commitment on the part of WG&SI to select a
          participant as the winners. Nothing herein amounts to a commitment by
          WG&SI to conduct further, similar or other contests in future.
        </li>
        <li>
          Incomplete information provided or failure to provide true and
          accurate information as stated in these terms and conditions, failure
          to submit proof of identification and/or original confirmation number
          upon collection of the Prize will automatically disqualify the
          participant. WG&SI shall have the absolute and sole discretion to
          determine whether any participant should be disqualified by reason of
          failure to abide by these terms and conditions, bad faith, fraud or
          any other legitimate reason.
        </li>
        <li>
          WG&SI reserves the right to change the terms and conditions of this
          contest, including extending, withdrawing or discontinuing the same
          without notice, without assigning any reason, at its sole discretion
          without any liability.
        </li>
        <li>
          There is no cash alternative to the stated prizes, and the prizes are
          non-transferable and non-refundable. WG&SI reserves the right to
          substitute either any part of Prize or whole Prize for similar prize
          of equal or greater value.
        </li>
        <li>
          WG&SI shall not be liable in any manner whatsoever for any claims,
          losses, damage, costs or expenses in connection with or arising from
          this Competition, the redemption or use of the Prizes.
        </li>
        <li>
          WG&SI reserves the right to request proof of a participant's
          eligibility in the event that there is a doubt over his/her
          eligibility for the promotion.
        </li>
        <li>
          Subject to applicable laws, WG&SI shall not be liable for any losses,
          taxes, liabilities or inconvenience suffered by any participant as a
          result of these terms and conditions, entering into this Competition
          or accepting any part of the Prize.
        </li>
        <li>
          WG&SI shall not be liable under or in connection with these terms and
          conditions or for this Competition or using the Prize for any
          indirect, special or consequential cost, expense, loss or damage even
          if such cost, expense, loss or damage was reasonably foreseeable or
          might reasonably have been contemplated by the participant and WG&SI
          and whether arising from breach of contract, tort, negligence, breach
          of statutory duty or otherwise.
        </li>
        <li>
          WG&SI accepts no responsibility for difficulties experienced in
          submitting an entry to this Contest. WG&SI does not accept
          responsibility for (1) lost, late or undelivered entries or (2) any
          technical or access issue, failure, malfunction or difficulty that
          might hinder the ability of a participant to enter the Competition or
          (3) any event which may cause the Competition to be disrupted or
          corrupted.
        </li>
        <li>
          In addition to these terms & conditions, other specific terms may be
          imposed by WG&SI from time to time to deal with any unforeseen
          situation. WG&SI also reserves the absolute right to change the terms
          and conditions contained herein and, or, any other rules and
          regulations in respect of the Competition at any time without any
          notice, the itinerary, without assigning any reason and without any
          liability whatsoever. Participants are requested to refer to such
          other terms and conditions, if any, which may be intimated separately
          as WG&SI considers fit. However, no obligation is cast on WG&SI to
          separately intimate each individual participant with regard to such
          additional terms and conditions.
        </li>
        <li>
          WG&SI may (i) extend (ii) terminate or suspend the Competition at any
          time due to circumstances beyond its control (iii) substitute a prize
          (or any part of a Prize).
        </li>
        <li>
          Material sent to WG&SI including feedback and other communications of
          any kind as well as submission of an entry to this Competition shall
          be deemed to be non-confidential. WG&SI shall be free to reproduce,
          distribute and publicly display such feedback, materials without
          limitation or obligation of any kind. WG&SI is also free to use any
          ideas, concepts, know-how or techniques contained in such submissions
          or materials for any purpose.
        </li>
        <li>
          Each participant understands that each of the officials of WG&SI
          engaged in the organization and management of this Competition
          including its directors, officers, partners, employees, consultants,
          and agents are under no obligation to render any advice or service to
          any participant in respect of this Competition.
        </li>
        <li>
          The participant undertakes to indemnify and keep WG&SI harmless and
          indemnified against any loss, damage, claims, costs and expenses which
          may be incurred or suffered by WG&SI due to breach of any of the terms
          and conditions herein contained.
        </li>
        <li>
          Any disputes, differences and/or any other matters in relation to and
          arising out of this Promotion and or pertaining to these terms and
          conditions shall be referred to arbitration under the Arbitration &
          Conciliation Act, 1996. The venue of arbitration shall be Gurugram,
          Haryana. The Promotion shall be governed by and construed in
          accordance with applicable laws in India and will be subject to
          exclusive jurisdiction of the courts at Gurugram alone. The Rules of
          Indian Council of Arbitration(“Rules”) shall apply to the arbitration
          proceedings and the arbitration shall be conducted by a sole
          arbitrator to be appointed as per the Rules.
        </li>
        <li>
          Each participant must ensure that his or her participation in the
          promotion is lawful in accordance with the laws of India. Neither nor
          its representatives or agents shall be taken to make any
          representations, express or implied, as to the lawfulness of any
          participant's participation in the Competition.
        </li>
        <li>
          Entrants are deemed to accept these terms and conditions by entering
          the Contest.
        </li>
        <li>The decision of WG&SI will be final and binding.</li>
      </ul>

      <h3>To claim the prize:</h3>
      <ul>
        <li>
          The winner needs to submit all the details as per the requirements of
          WG&SI and within the stipulated timeframes in order to claim the
          prize.
        </li>
      </ul>
    </div>
  );
}

export default Terms;
