import "bootstrap/dist/css/bootstrap.min.css";
import { useEffect, useState } from "react";
import "./App.css";
import Location from "./Components/Location";
import Logo from "./Components/Logo";
import ClaimForm from "./Components/Popups/ClaimForm";
import Confirmation from "./Components/Popups/Confirmation";
import SpinWheel from "./Components/Popups/SpinWheel";
import { getCookie, setCookie } from "./functions/cookie";
import app from "./firebase";
import {
  collection,
  doc,
  FieldValue,
  getDoc,
  getDocs,
  getFirestore,
  increment,
  setDoc,
} from "firebase/firestore";
import ClaimPrize from "./Components/Popups/ClaimPrize";

function App() {
  const [popup, setPopup] = useState({ display: false, type: "spinWheel" });

  const screen = getCookie("screen");

  useEffect(() => {
    switch (screen) {
      case "spinWheel":
        setPopup({ display: true, type: "spinWheel" });
        break;
      case "claimPrize":
        setPopup({ display: true, type: "claimPrize" });
        break;
      case "claimForm":
        setPopup({ display: true, type: "claimForm" });
        break;
      case "confirmation":
        setPopup({ display: true, type: "confirmation" });
        break;
      default:
        setPopup({ display: false, type: "spinWheel" });
        break;
    }
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [popup]);

  useEffect(() => {
    async function checkVisitor() {
      let visitorCookie = getCookie("visited");
      if (visitorCookie) {
        // do nothing
      } else {
        let db = getFirestore(app);
        let docRef = doc(db, "visitors", "YbkzR349fhVtZUEHps6w");
        setDoc(docRef, { count: increment(1) }, { merge: true });
        setCookie("visited", true);
      }
    }

    checkVisitor();
  }, []);

  return (
    <div className="app-background">
      <div className="main-container" id="main_container">
        {popup.display === false ? (
          <>
            <Logo />
            <Location updatePopup={setPopup} />
          </>
        ) : (
          ""
        )}

        {popup.display === true && popup.type === "spinWheel" ? (
          <SpinWheel updatePopup={setPopup} />
        ) : (
          ""
        )}

        {popup.display === true && popup.type === "claimPrize" ? (
          <>
            <Logo />
            <ClaimPrize updatePopup={setPopup} />
          </>
        ) : (
          ""
        )}

        {popup.display === true && popup.type === "claimForm" ? (
          <>
            <Logo />
            <ClaimForm updatePopup={setPopup} />
          </>
        ) : (
          ""
        )}

        {popup.display === true && popup.type === "confirmation" ? (
          <>
            <Logo />
            <Confirmation />
          </>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default App;
