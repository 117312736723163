import React, { useEffect, useRef } from "react";
import { deleteCookie, getCookie, setCookie } from "../../functions/cookie";
import { Button } from "react-bootstrap";
import app from "../../firebase";
import { getFirestore, doc, setDoc } from "firebase/firestore";

function ClaimPrize(props) {
  const db = getFirestore(app);
  const prize = getCookie("prize");
  const prizeType = getCookie("prize_type");
  const attempt = getCookie("attempt");
  const location = getCookie("location");
  const outlet = getCookie("outlet");
  let resultRef = useRef(null);

  useEffect(() => {
    if (prize !== "try_again") {
      let mainContainer = document.getElementById("main_container");
      mainContainer.classList.add("fireworks");
      if (resultRef.current !== undefined) {
        const timeoutId = setInterval(() => {
          resultRef.current.style.transform += "rotateY(180deg)";
        }, 2000);

        // Cleanup function to clear the timeout if the component unmounts
        return () => clearInterval(timeoutId);
      }
    } else {
      console.log(resultRef.current);
    }
  }, []);

  const tryAgain = () => {
    setCookie("attempt", attempt - 1, 1);
    deleteCookie("prize_type");
    deleteCookie("prize");
    setCookie("screen", "spinWheel", 1);
    window.location.reload();
  };

  function generateToken() {
    const randomNum = Math.random() * 9000;
    return Math.floor(1000 + randomNum);
  }

  const submitDataForDrink = async ({ token }) => {
    const date = new Date();
    let dateInMilliSeconds = date.getTime();
    let code = token;
    let postData = {
      name: "NA",
      email: "NA",
      date: date,
      delivery_status: "NA",
      location: location,
      outlet_name: outlet,
      prize: prize,
      bill: "NA",
      prize_type: prizeType,
      address: "NA",
      token: code,
    };

    let response = await setDoc(
      doc(db, "customers", dateInMilliSeconds.toString()),
      postData
    );
    console.log(response);
  };

  const claim = () => {
    if (prizeType === "drink") {
      let calculateToken = generateToken();
      setCookie("token", calculateToken, 1);
      let token = getCookie("token");
      submitDataForDrink({ token });
    }
    let mainContainer = document.getElementById("main_container");
    mainContainer.classList.remove("fireworks");
    props.updatePopup({ display: true, type: "claimForm" });
    setCookie("screen", "claimForm", 1);
  };
  return (
    <>
      <div className="result-container">
        <div className="result">
          {prize === "one_plus_one" ? (
            <>
              <div className="spin-wheel-result">
                <div className="flip-box-inner" ref={resultRef}>
                  <div className="flip-box-front">
                    <p>CHEERS!</p>
                    <h2>You’ve Won</h2>
                  </div>
                  <div className="flip-box-back">
                    <p>Crewtime Special 1 + 1</p>
                  </div>
                </div>
              </div>
            </>
          ) : prize === "branded_tshirt" ? (
            <div className="spin-wheel-result">
              <div className="flip-box-inner" ref={resultRef}>
                <div className="flip-box-front">
                  <p>CHEERS!</p>
                  <h2>You’ve Won</h2>
                </div>
                <div className="flip-box-back">
                  <p>Grant's Branded T-Shirt</p>
                </div>
              </div>
            </div>
          ) : prize === "branded_cap" ? (
            <div className="spin-wheel-result">
              <div className="flip-box-inner" ref={resultRef}>
                <div className="flip-box-front">
                  <p>CHEERS!</p>
                  <h2>You’ve Won</h2>
                </div>
                <div className="flip-box-back">
                  <p>Grant's Branded Cap</p>
                </div>
              </div>
            </div>
          ) : (
            <div className="spin-wheel-result">
              <div className="flip-box-inner">
                <div className="flip-box-front">
                  {attempt > 0 ? (
                    <h2 className="try_again_content">Try Again</h2>
                  ) : (
                    <h2 className="try_again_content">
                      TRY AGAIN ON YOUR NEXT VISIT
                    </h2>
                  )}
                </div>
                <div className="flip-box-back"></div>
              </div>
            </div>
          )}
        </div>
        {prize === "one_plus_one" ? (
          <div className="disclaimer">
            <p>
              This offer is valid only on purchase of 30ml, 60ml, 90ml, 750ml
              bottle and any cocktail of Grant's Triple Wood.
            </p>
          </div>
        ) : (
          ""
        )}
        <div className="button-container">
          {prize !== "try_again" ? (
            <Button className="claim" onClick={() => claim()}>
              Claim Prize
            </Button>
          ) : attempt > 0 ? (
            <Button onClick={() => tryAgain()}>Try Again</Button>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
}

export default ClaimPrize;
